import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'

const gui = new dat.GUI()

const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Objects
const spheres = []
const sphereGeometry = new THREE.SphereGeometry(0.5, 32, 32)

const createSphere = (position) => {
    // Generate a random number between 1 and 100
    const randomNumber = Math.floor(Math.random() * 100) + 1

    // Create sphere material
    const sphereMaterial = new THREE.MeshBasicMaterial({color: 0xffcc00})

    // Create sphere mesh
    const mesh = new THREE.Mesh(sphereGeometry, sphereMaterial)
    mesh.position.copy(position)
    scene.add(mesh)

    // Create a canvas for the text texture
    const textCanvas = document.createElement('canvas')
    textCanvas.width = 512
    textCanvas.height = 256
    const textContext = textCanvas.getContext('2d')
    textContext.font = '120px Arial'
    textContext.fillStyle = 'black' // Black text color
    textContext.textBaseline = 'middle'
    textContext.textAlign = 'center'
    textContext.fillText(randomNumber.toString(), textCanvas.width / 2, textCanvas.height / 2)

    const textTexture = new THREE.CanvasTexture(textCanvas)

    // Create text material
    const textMaterial = new THREE.MeshBasicMaterial({
        map: textTexture,
        transparent: true,
    })

    // Create text mesh
    const textMesh = new THREE.Mesh(sphereGeometry, textMaterial)

    // Position the text mesh
    textMesh.position.copy(position)

    // Rotate the text mesh
    textMesh.rotation.y = -Math.PI * 0.5

    // Move the text mesh slightly in front of the sphere
    textMesh.translateX(0.01)

    // Create a group to hold both the sphere and the text
    const group = new THREE.Group()
    group.add(mesh)
    group.add(textMesh)
    group.position.copy(position)

    group.randomNumber = randomNumber
    group.mesh = mesh

    scene.add(group)

    return group // Return the group instead of the mesh
}


for (let i = 0; i < 10; i++) {
    const position = {
        x: i * 1 - 4.5,
        y: 0,
        z: 0
    }
    const sphereGroup = createSphere(position) // Create the group
    spheres.push(sphereGroup) // Add the group to the array
}

// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 2
camera.position.z = 25

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

scene.add(camera)

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Button functions
let animationInProgress = false; // Add this flag at the top level of your script

const sortSpheres = async () => {
    if (animationInProgress) return;

    animationInProgress = true;
    sortButton.disabled = true; // Disable the "Sort" button

    // Disable the button and apply the disabled style
    resetButton.disabled = true;
    resetButton.classList.add('button-disabled');

    // Bubble Sort
    const n = spheres.length;
    
    for (let i = 0; i < n; i++) {
        let swapped = false; // Flag to track if any swaps occurred in this pass
        for (let j = 0; j < n - (i+1); j++) {
            const currentSphereGroup = spheres[j];
            const nextSphereGroup = spheres[j + 1];
            spheres[j].mesh.material.color.setHex(0x00ccff); // Blue color
            spheres[j + 1].mesh.material.color.setHex(0x00ccff); // Blue color

            if (currentSphereGroup.randomNumber > nextSphereGroup.randomNumber) {
                // Swap locations
                const animateRight = animateMovement(spheres[j], spheres[j].position.x + 2);
                const animateLeft = animateMovement(spheres[j+1], spheres[j+1].position.x - 2);

                const animations = [];
                animations.push(animateLeft, animateRight);
                await Promise.all(animations);

                // Swap the sphere groups
                spheres[j] = nextSphereGroup;
                spheres[j + 1] = currentSphereGroup;

                swapped = true;
            }
            await new Promise(resolve => setTimeout(resolve, 500));
            spheres[j].mesh.material.color.setHex(0xffcc00); // Yellow color
            spheres[j + 1].mesh.material.color.setHex(0xffcc00); // Yellow color
        }
        if (!swapped) {
            break;
        }
    }
    animationInProgress = false;
    sortButton.disabled = false; // Re-enable the "Sort" button

    // Re-enable the button and remove the disabled style
    resetButton.disabled = false;
    resetButton.classList.remove('button-disabled');
}

const resetSpheres = () => {
    if (animationInProgress) return;

    // Remove the existing spheres from the scene
    spheres.forEach(sphereGroup => {
        scene.remove(sphereGroup);
    });

    // Clear the array
    spheres.length = 0;

    // Create new spheres and add them to the scene
    for (let i = 0; i < 10; i++) {
        const position = {
            x: i * 1 - 4.5,
            y: 0,
            z: 0
        };
        const sphereGroup = createSphere(position);
        spheres.push(sphereGroup);
        scene.add(sphereGroup);
    }
};

// Event listeners for buttons
const sortButton = document.getElementById('sortButton');
sortButton.addEventListener('click', sortSpheres);

const resetButton = document.getElementById('resetButton');
resetButton.addEventListener('click', resetSpheres);

// Animate
const clock = new THREE.Clock()
const movementDuration = .65 // in seconds

const animateMovement = async (sphere, targetX) => {
    return new Promise(resolve => {
        const initialX = sphere.position.x
        const initialY = sphere.position.y
        const startTime = clock.getElapsedTime()
        const direction = targetX > initialX ? 1 : -1 // Determine the direction of movement

        const animateFrame = () => {
            const currentTime = clock.getElapsedTime()
            const progress = Math.min((currentTime - startTime) / movementDuration, 1)
            const progressX = initialX + (targetX - initialX) * progress

            // Calculate vertical movement using half wave of sine for right and negative sine for left
            const frequency = Math.PI // Half a wave
            const amplitude = 1 // Adjust amplitude to control the height
            let progressY
            if (direction === 1) {
                progressY = initialY + amplitude * Math.sin(frequency * progress)
            } else {
                progressY = initialY - amplitude * Math.sin(frequency * progress)
            }

            sphere.position.x = progressX
            sphere.position.y = progressY

            if (progress < 1) {
                requestAnimationFrame(animateFrame)
            } else {
                sphere.position.x = targetX
                sphere.position.y = initialY // Ensure sphere returns to its original y position
                resolve()
            }
        }

        animateFrame()
    })
}

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    controls.update()

    renderer.render(scene, camera)

    window.requestAnimationFrame(tick)
}
tick()